import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {connect, Page} from '@henrybuilt/react-app/src';
import {Form} from 'henrybuilt-react-library';
import {Link} from 'react-router-dom';
import Moment from 'moment';
import './edit-transaction-page.scss';

class EditTransactionPage extends Page {
  key = 'edit-transaction';
  authGroup = 'employee';
  state = {};

  constructor(props) {
    super(props);

    this.bind([
      'handleSave',
      'uploadFile',
      'deleteTransaction',
      'onEmailClientClick',
      'onPaymentRecievedClick'
    ]);
  }

  async componentDidMount() {
    var user = _.get(this, 'props.session.user');

    if (user) {
      var {transaction, users} = await lib.api.get({
        transaction: {where: this.props.match.params, fields: ['fileUrl', '*']},
        users: {where: {level: [2, 3]}},
        // projects: {fields: ['title', 'id', 'userId', 'ownerUserId', 'clientName']}
      });

      transaction = _.defaults(transaction, {
        description: '',
        internalNotes: ''
      });

      this.setState({transaction, users, lastSavedTransaction: transaction});
    }
  }

  handleChangeFor({key}) {
    return ({value}) => this.setState((prevState)=> {
      var transaction = {...prevState.transaction};

      if (key === 'projectId') {
        value = _.toNumber(value) || null;
      }

      transaction[key] = value;

      return {transaction};
    });
  }

  async onEmailClientClick() {
    if (this.transactionIsValid(this.state.lastSavedTransaction)) {
      var shouldSendEmail = window.confirm(`Just checking!

This sends the client an automated email with the title, amount, your note, and a link requesting payment for this transaction.`);

      var emailSubject = window.prompt('Email Subject: ', `Payment Request - ${this.state.transaction.title}`);

      if (shouldSendEmail && emailSubject) {
        await lib.api.request({uri: 'transactions/notify-client', body: {transaction: this.state.transaction, emailSubject, mode: 'paymentRequest'}, useActualErrorMessages: true});

        window.location = `${window.location.origin}/transactions`;
      }
    }
  }

  async onPaymentRecievedClick() {
    if (this.transactionIsValid(this.state.lastSavedTransaction)) {
      var shouldSendEmail = window.confirm(_.get(this.state.transaction, 'payerEmails') ? `Just checking!

This sends the client an automated email confirming we received their check or wire.` : `Just checking!

There is no client email entered, this will send an internal email that we have recieved the client's payment.`);

      if (shouldSendEmail) {
        await lib.api.request({uri: 'transactions/notify-client', body: {transaction: this.state.transaction, mode: 'paymentReceived'}, useActualErrorMessages: true});

        window.location = `${window.location.origin}/transactions`;
      }
    }
  }

  hasUnsavedChangesFor(key) {
    return this.state.transaction && this.state.lastSavedTransaction && this.state.transaction[key] !== this.state.lastSavedTransaction[key];
  }

  async handleSave() {
    var {transaction} = this.state;

    var props = _.pick(transaction, ['amountInCents', 'title', 'brandKey', 'amountInCents', 'description', 'internalNotes', 'payerEmails', 'payeeUserId', 'type', 'status', 'projectId']);

    await lib.api.update('transaction', {
      where: {id: transaction.id},
      props
    });

    setTimeout(() => this.setState({lastSavedTransaction: transaction, transaction, amountHasUnsavedChanges: false}), 1000);
  }

  invalidKeysFor(transaction) {
    var invalidKeys = [];

    if (!transaction.title || transaction.title === '') invalidKeys.push('title');

    _.forEach(['type', 'brandKey', 'description'], key => {
      if (!transaction[key]) invalidKeys.push(key);
    });

    return invalidKeys;
  }

  transactionIsValid(transaction) {
    return transaction && this.invalidKeysFor(transaction).length === 0;
  }

  get isValid() {
    return this.transactionIsValid(this.state.transaction);
  }

  get invalidKeys() {
    return this.invalidKeysFor(this.state.transaction);
  }

  get hasUnsavedChanges() {
    return !_.isEqual(this.state.lastSavedTransaction, this.state.transaction) || this.state.amountHasUnsavedChanges;
  }

  async uploadFile(e) {
    var file = _.get(e, 'target.files[0]');

    this.setState({uploadingFile: true});

    if (file) {
      await lib.api.update('transaction', {
        where: {id: this.state.transaction.id},
        props: {hasFile: true}
      }, {files: [file]});
    }

    var updatedTransaction = await lib.api.get('transaction', {
      where: {id: this.state.transaction.id},
      fields: ['fileUrl', '*']
    });

    var transaction = {...this.state.transaction, fileUrl: updatedTransaction.fileUrl};

    this.setState({transaction, lastSavedTransaction: transaction});

    setTimeout(() => this.setState({uploadingFile: false}), 500);
  }

  async deleteTransaction() {
    var shouldDeleteTransaction = window.confirm(`Are you sure? Never delete a transaction that's been sent to a client.`);

    if (shouldDeleteTransaction && this.state.transaction) {
      this.setState({isDeleting: true});

      await lib.api.update('transaction', {where: {id: this.state.transaction.id}, props: {deleted: 1}});

      setTimeout(() => {
        this.setState({isDeleting: false});

        window.location = `${window.location.origin}/transactions`;
      }, 500);
    }
  }

  render() {
    var {transaction, users} = this.state;

    return super.render(
    <div className='edit-transaction-page-content'>
      {transaction && (
        <>
          <div className='edit-transaction-title-container'>
            <div className='edit-transaction-title'>Edit Transaction</div>
          </div>
          <div>
            <Link className='back-link' to={'/transactions'}>all transactions</Link>
            {transaction.lastNotification && <span className='last-notification-text'>last contact: {Moment.utc(transaction.lastNotification).local().format('MM/DD ha')}</span>}
          </div>
          <div {...className(['edit-transaction-form-container', this.hasUnsavedChanges && 'has-unsaved-changes', this.invalidKeys.length && 'is-invalid'])}>
            <Form on={{submit: this.handleSave}} submitText='Save' useSubmitInput isSubmittingText='Saving...'>
              <div className='wrapping-form-fields'>
                <Form.TextInput
                  {...className([
                    'form-input',
                    _.includes(this.invalidKeys, 'title') && 'is-invalid',
                    this.hasUnsavedChangesFor('title') && 'has-unsaved-changes'
                  ])}
                  label='Title'
                  on={{input: this.handleChangeFor({key: 'title'})}}
                  value={transaction.title}
                />
                <div style={{display: 'inline-block'}}
                  onClick={() => {
                    if ((transaction.amountPaidInCents > 0 || transaction.status === 'partiallyPaid')) {
                      alert(`This transaction has already received partial payment from a client and shouldn't be modified.

                      Please use a new transaction or contact the SF team for assistance if this is an exception.`)
                    }
                  }}
                >
                  <Form.DollarInput
                    {...className([
                      'form-input',
                      this.state.amountHasUnsavedChanges && 'has-unsaved-changes',
                      (transaction.amountPaidInCents > 0 || transaction.status === 'partiallyPaid') && 'is-disabled'
                    ])}
                    disabled={(transaction.amountPaidInCents > 0 || transaction.status === 'partiallyPaid')}
                    zeroIsInvalid
                    label='Amount ($)'
                    placeholder='0.00'
                    on={{
                      input: ({value}) => {
                        this.setState({amountHasUnsavedChanges: true});
                      },
                      change: ({value}) => {
                        this.setState((prevState) => ({amountHasUnsavedChanges: true, transaction: {...prevState.transaction, amountInCents: value}}));
                      },

                    }}
                    value={transaction.amountInCents || 0}
                  />
                </div>
                <Form.DropdownInput
                  {...className(['form-input', _.includes(this.invalidKeys, 'brandKey') && 'is-invalid', this.hasUnsavedChangesFor('brandKey') && 'has-unsaved-changes'])}
                  label='Brand'
                  value={transaction.brandKey}
                  on={{change: this.handleChangeFor({key: 'brandKey'})}}
                  options={[
                    {title: 'Henrybuilt', value: 'hb'},
                    {title: 'Space Theory', value: 'st'},
                    {title: 'Primary Objects', value: 'po'}
                  ]}
                />
                <Form.DropdownInput
                {...className(['form-input', _.includes(this.invalidKeys, 'type') && 'is-invalid', this.hasUnsavedChangesFor('type') && 'has-unsaved-changes'])}
                  label='Type'
                  value={transaction.type}
                  on={{change: this.handleChangeFor({key: 'type'})}}
                  options={[
                    {title: 'Initial Deposit', value: 'initialDeposit'},
                    {title: 'Additions', value: 'additions'},
                    {title: 'Signoff', value: 'signoff'},
                    {title: 'Cost Revision', value: 'costRevision'},
                    {title: 'PIF', value: 'pif'},
                    {title: 'Change Order', value: 'changeOrder'},
                    {title: 'Storage', value: 'storage'},
                    {title: 'Field Expert Services', value: 'fieldExpertServices'},
                    {title: 'Other (All Payment Types)', value: 'other'}
                  ]}
                />
                <Form.TextInput
                  {...className([
                    'form-input',
                    this.hasUnsavedChangesFor('payerEmails') && 'has-unsaved-changes'
                  ])}
                  on={{input: this.handleChangeFor({key: 'payerEmails'})}}
                  label='Client Email'
                  value={transaction.payerEmails}
                />
                <Form.DropdownInput
                  {...className(['form-input', this.hasUnsavedChangesFor('payeeUserId') && 'has-unsaved-changes'])}
                  label='Prepared By'
                  value={transaction.payeeUserId ? transaction.payeeUserId.toString() : ''}
                  on={{change: this.handleChangeFor({key: 'payeeUserId'})}}
                  options={_.sortBy(_.map(users, user => ({title: _.startCase(user.name), value: user.id.toString()})), 'title')}
                />
                <div>
                  <Form.DropdownInput
                    {...className(['form-input', this.hasUnsavedChangesFor('status') && 'has-unsaved-changes'])}
                    label='Status'
                    value={transaction.status ? transaction.status.toString() : ''}
                    on={{change: this.handleChangeFor({key: 'status'})}}
                    options={[
                      {title: 'Unpaid', value: 'unpaid'},
                      {title: 'Partially Paid', value: 'partiallyPaid'},
                      {title: 'Initialized By Client', value: 'initializedByClient'},
                      {title: 'Paid', value: 'paid'},
                      {title: 'Overdue', value: 'overdue'}
                    ]}
                  />
                  {/* <Form.DropdownInput
                    {...className(['form-input', this.hasUnsavedChangesFor('projectId') && 'has-unsaved-changes'])}
                    label='Project'
                    value={transaction.projectId ? transaction.projectId.toString() : ''}
                    on={{change: this.handleChangeFor({key: 'projectId'})}}
                    options={[{title: 'Not Yet Chosen', value: '-1'}, ..._.sortBy(_.map(projects, project => ({...project, title: `${project.title || project.clientName || 'Order'} - ${project.id}`, value: project.id.toString()})), [project => {
                      return _.includes([project.ownerUserId, project.userId], _.get(this.props, 'session.user.id')) ? 0 : 1;
                    }, project => -project.id])]}
                  /> */}
                  <Form.TextInput
                    {...className([
                      'form-input',
                      this.hasUnsavedChangesFor('projectId') && 'has-unsaved-changes'
                    ])}
                    on={{input: this.handleChangeFor({key: 'projectId'})}}
                    label='Project ID'
                    value={_.toString(transaction.projectId) || ''}
                  />
                </div>
              </div>
              <Form.TextInput
                {...className([
                  'form-input multiline description-input',
                  _.includes(this.invalidKeys, 'description') && 'is-invalid',
                  this.hasUnsavedChangesFor('description') && 'has-unsaved-changes'
                ])}
                isMultiline
                on={{input: this.handleChangeFor({key: 'description'})}}
                label='Note to Client'
                value={transaction.description}
              />
              {/* <Form.TextInput
                {...className([
                  'form-input multiline internal-notes-input',
                  this.hasUnsavedChangesFor('internalNotes') && 'has-unsaved-changes'
                ])}
                isMultiline
                on={{input: this.handleChangeFor({key: 'internalNotes'})}}
                label='Internal Notes'
                value={transaction.internalNotes}
              /> */}
              <div className='file-upload-container'>
                {transaction.fileUrl && <a className='file-link' href={transaction.fileUrl} rel='noopener noreferrer' target='_blank'>{this.state.uploadingFile ? 'updating...' : 'view invoice'}</a>}
                <input name='file-upload' id='file-upload-button' type='file' accept='image/*,.doc,.docx,.pdf,.md,.txt' onInput={this.uploadFile}/>
                <label className='button' htmlFor='file-upload-button'>{this.state.uploadingFile ? 'Uploading...' : 'Upload Invoice'}</label>
              </div>
              {this.transactionIsValid(this.state.transaction) && this.transactionIsValid(this.state.lastSavedTransaction) && (
              <>
                <a className='preview-button button' rel='noopener noreferrer' target='_blank' href={`${window.location.origin}/transactions/${transaction.token}`}>Preview</a>
                {transaction.status !== 'initializedByClient' && transaction.payerEmails && (<div className='email-client-button button' onClick={this.onEmailClientClick}>Send Payment Request Email</div>)}
                {transaction.status === 'initializedByClient' && (<div className='payment-received-button button' onClick={this.onPaymentRecievedClick}>Wire/ACH/Check received</div>)}
              </>
              )}
              <div className='delete-button button' onClick={this.deleteTransaction}>{this.state.isDeleting ? 'Deleting...' : 'Delete'}</div>
            </Form>
          </div>
        </>
      )}
    </div>
    );
  }
}

export default connect({})(EditTransactionPage);
