import {_, Component, React, className, lib, AppWrapper, createStore} from '@henrybuilt/react-app'; //eslint-disable-line
import {Route, Switch} from 'react-router-dom';
import TransactionsIndexPage from 'components/pages/transactions/index/transactions-index-page.js';
import EditTransactionPage from 'components/pages/transactions/edit/edit-transaction-page.js'
import ShowTransactionPage from 'components/pages/transactions/show/show-transaction-page.js'
import './app.scss';

var store = createStore();

class App extends Component {
  render() {
    return (
      <div className='app'>
        <AppWrapper store={store} appKey='checkout'>
          <Switch>
            <Route path={['', '/', '/transactions']} exact component={TransactionsIndexPage}/>
            <Route path={['/edit-transaction/:id']} exact component={EditTransactionPage}/>
            <Route path={['/transactions/:token']} exact component={ShowTransactionPage}/>
          </Switch>
        </AppWrapper>
      </div>
    );
  }
}

export default App;
