import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {connect, Page} from '@henrybuilt/react-app/src';
import Filters from './filters/filters.js';
import Moment from 'moment';
import {Link} from 'react-router-dom';
import './transactions-index-page.scss';

class TransactionsIndexPage extends Page {
  key = 'transactions-index';
  authGroup = 'employee';
  state = {filters: {}, showAll: false};

  constructor(props) {
    super(props);

    this.bind(['handleFiltersChange', 'createTransaction', 'handleSearchChange']);
  }

  async componentDidMount() {
    var {transactions, users} = await lib.api.get({
      transactions: {where: {deleted: 0}, order: [{field: 'id', direction: 'desc'}]},
      users: {where: {level: [2, 3]}},
      // projects: {fields: ['title', 'id', 'userId', 'ownerUserId', 'clientName']}
    });

    var totalCollected = _.sum(_.map(_.filter(transactions, {status: 'paid'}), 'amountInCents'));

    this.setState({transactions, users, totalCollected});
  }

  async requestTransactions() {
    var {filters} = this.state;

    var transactions = await lib.api.get('transactions', {where: _.omit(filters, ['searchTerm']), order: [{field: 'id', direction: 'desc'}]});

    this.setState({transactions});
  }

  handleFiltersChange({filters, showAll}) {
    this.setState({filters, showAll}, () => {
      this.requestTransactions();
    });
  }

  handleSearchChange({searchTerm}) {
    this.setState({searchTerm});
  }

  async createTransaction() {
    var title = prompt('Transaction title:');

    if (title) {
      var props = {title};

      if (_.get(this, 'props.session.user.id')) props.payeeUserId = _.get(this, 'props.session.user.id');

      var transaction = await lib.api.create('transaction', {props});

      window.location = `${window.location.origin}/edit-transaction/${transaction.id}`;
    }
  }

  render() {
    var {transactions, users, searchTerm, filters, totalCollected, showAll} = this.state;

    if (filters.status !== 'paid' && !showAll) transactions = _.filter(transactions, transaction => transaction.status !== 'paid');
    if (filters.source !== 'design-engine') transactions = _.filter(transactions, transaction => transaction.source !== 'design-engine');
    if (filters.type !== 'po cart') transactions = _.filter(transactions, transaction => !(transaction.source === 'po-website' && transaction.status !== 'paid'));

    if (transactions && searchTerm) {
      transactions = _.filter(transactions, transaction => _.includes(_.toLower(transaction.title), _.toLower(searchTerm)));
    }

    return super.render(
      <div className='transactions-index-page-content'>
        <div className='transactions-title'>Transactions - {lib.price.dinero((totalCollected || 0)/ 100).toFormat('$0,0.00')} collected</div>
        <Filters onFiltersChange={this.handleFiltersChange} onSearchChange={this.handleSearchChange} {...{users, session: this.props.session}}/>
        <div className='button create-transaction' onClick={this.createTransaction}>+</div>
        <div className='transactions-table-container'>
          <div className='transactions-table'>
            <div className='header-row row'>
              {/* <div className='cell'>ID</div> */}
              <div className='cell'>Project ID</div>
              <div className='cell'>Title</div>
              <div className='cell'>Brand</div>
              <div className='cell'>Status</div>
              <div className='cell'>Last Contact</div>
              <div className='cell'>Prepared By</div>
              <div className='cell'>Type</div>
              <div className='cell'>Amount</div>
            </div>
            {transactions && _.map(transactions, transaction => {
              var payeeName = _.get(_.find(this.state.users, {id: transaction.payeeUserId}), 'name');

              return (
                <Link className='transaction row' to={`/edit-transaction/${transaction.id}`} key={transaction.id}>
                  {/* <div className='cell'>{transaction.id}</div> */}
                  <div className='cell'>{transaction.projectId === -1 ? '' : transaction.projectId}</div>
                  <div className='title-cell cell'>{transaction.title}</div>
                  <div className='cell'>{_.toUpper(transaction.brandKey)}</div>
                  <div className='cell'>{_.startCase(transaction.status)}</div>
                  <div className='cell'>{transaction.lastNotification && Moment.utc(transaction.lastNotification).from(Moment.utc())}</div>
                  <div className='cell'>{payeeName}</div>
                  <div className='cell'>{transaction.type === 'pif' ? 'PIF' : _.startCase(transaction.type)}</div>
                  <div className='cell'>{lib.price.dinero((transaction.amountInCents || 0) / 100).toFormat('$0,0.00')}</div>
                </Link>
              );
            })}
          </div>
        </div>
        {/*<div className='page-controls'>
          <div className='last-page button'>&lt;</div>
          <div className='page-status'>1 / 10</div>
          <div className='next-page button'>&gt;</div>
        </div>*/}
      </div>
    );
  }
}

export default connect({})(TransactionsIndexPage);
