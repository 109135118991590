import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import PaymentMethodBody from '../payment-method/payment-method-body/payment-method-body.js';
import {brandKeyNameMap} from 'helpers/brand/brand-helper.js';
import './electronic-check-form.scss';

var forte = window.forte;

export default class ElectronicCheckBody extends PaymentMethodBody {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      accountType: 'checking'
    };
  }

  async onSubmit() {
    var {transaction, receiptEmails} = this.props;
    var {state} = this;

    //HINT when testing make sure to use sandbox version in index.html
    await super.onSubmit({processTransaction: async () => {
      await new Promise((resolve, reject) => {
        forte.createToken({
          api_login_id: process.env[`REACT_APP_${transaction.brandKey === 'st' ? 'ST' : 'HB'}_FORTE_PUBLIC_KEY`], //WARNING not actually sensitive
          account_number: state.accountNumber.trim(),
          routing_number: state.routingNumber.trim(),
          account_type: state.accountType
        }).success(async (response) => {
          if (response.onetime_token) {
            var requestParams = {
              echeck: {
                //WARNING ForteJS names this onetime_token
                //forte rest API names it one_time_token
                one_time_token: response.onetime_token,
                sec_code: 'WEB'
              },
              billing_address: {
                first_name: state.billingFirstName,
                last_name: state.billingLastName,
                physical_address: {
                  street_line1: state.billingStreetAddress,
                  locality: state.billingCity,
                  region: state.billingState,
                  postal_code: state.billingZip
                }
              },
              paymentMethod: 'echeck',
              partialPaymentAmount: state.partialPaymentAmount,
              receiptEmails,
              transaction
            };

            try {
              await lib.api.request({uri: 'transactions/pay', body: requestParams, useActualErrorMessages: true});
              await this.props.onSuccessfulPayment({amountPaidInCents: this.paymentAmountInCents});
            }
            catch {}

            resolve();
          }
        }).error((error) => {
          var errorMessage = error.response_description;

          //HINT formating Forte messages to something more client friendly
          errorMessage = errorMessage.replace(/_/g, ' ').replace(/expire/g, ' expiration ').replace(/Fortejs/g, brandKeyNameMap[transaction.brandKey]);

          alert(errorMessage);

          reject();
        });
      });
    }});
  }

  get formKeys() {
    return [
      'routingNumber',
      'accountNumber',
      'accountType',
      'billingFirstName',
      'billingLastName',
      'billingStreetAddress',
      'billingCity',
      'billingState',
      'billingZip'
    ];
  }

  get paymentTypeName() {
    return 'account';
  }


  render() {
    var {transaction} = this.props;
    var {state} = this;

    return super.render(!transaction ? null : (
      <>
        <div>
          <Form.TextInput {...className([_.includes(this.invalidKeys, 'routingNumber') && state.hasSubmitted && 'is-invalid'])} label='Routing #' placeholder='' value={state.routingNumber} on={{input: this.handleChangeFor({key: 'routingNumber'})}}/>
          <Form.TextInput {...className([_.includes(this.invalidKeys, 'accountNumber') && state.hasSubmitted && 'is-invalid'])} label='Account #' placeholder='' value={state.accountNumber} on={{input: this.handleChangeFor({key: 'accountNumber'})}}/>
          <Form.DropdownInput
            {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'accountType') && 'is-invalid'])}
            label='Account Type'
            on={{change: this.handleChangeFor({key: 'accountType'})}}
            value={this.state.accountType}
            options={[{title: 'Checking', value: 'checking'}, {title: 'Savings', value: 'savings'}]}
          />
        </div>
        <div>
          <Form.TextInput {...className([_.includes(this.invalidKeys, state.hasSubmitted && 'billingFirstName') && 'is-invalid'])} label='First Name' placeholder='' value={state.billingFirstName} on={{input: this.handleChangeFor({key: 'billingFirstName'})}}/>
          <Form.TextInput {...className([_.includes(this.invalidKeys, state.hasSubmitted && 'billingLastName') && 'is-invalid'])} label='Last Name' placeholder='' value={state.billingLastName} on={{input: this.handleChangeFor({key: 'billingLastName'})}}/>
        </div>
      </>
    ));
  }
}
