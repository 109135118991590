import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import './filters.scss';

export default class Filters extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.bind(['handleSearchChange']);

    this.handleSearchChange = _.debounce(this.handleSearchChange, 200);
  }

  handleSearchChange({value}) {
    this.setState({searchTerm: value}, () => {
      this.props.onSearchChange({searchTerm: value});
    });
  }

  handleChangeFor({key}) {
    return ({value}) => {
      var newFilters = this.state.filters || {};

      if (key === 'type') {
        if (value === 'de') {
          newFilters.source = 'design-engine';
          value = '';
        }
        else newFilters = _.omit(newFilters, 'source');
      }

      if (_.includes([''], value) || (key === 'status' && value === 'all')) {
        newFilters = _.omit(newFilters, key);
      }
      else {
        newFilters = {...newFilters, [key]: value};
      }

      this.setState({filters: {...newFilters}}, () => {
        this.props.onFiltersChange({filters: this.state.filters, showAll: key === 'status' && value === 'all'});
      });
    };
  }

  render() {
    var payeeOptions = [{title: 'payee', value: ''}];
    // var projectOptions = [{title: 'project', value: ''}];
    var {users} = this.props;

    payeeOptions.push(..._.sortBy(_.map(users, user => ({title: _.startCase(user.name), value: user.id.toString()})), 'title'));
    // projectOptions.push(..._.sortBy(_.map(projects, project => ({...project, title: `${project.title || project.clientName || 'Order'} - ${project.id}`, value: project.id.toString()})), [project => {
    //   return _.includes([project.ownerUserId, project.userId], _.get(this.props, 'session.user.id')) ? 0 : 1;
    // }, project => -project.id]));

    return <div {...className(['index-filters'])}>
      <div>
        <Form.TextInput className='search-input' placeholder='search...' on={{input: this.handleSearchChange}}/>
        <Form.DropdownInput
          placeholder='brand'
          on={{change: this.handleChangeFor({key: 'brandKey'})}}
          options={[
            {title: 'brand', value: ''},
            {title: 'Henrybuilt', value: 'hb'},
            {title: 'Space Theory', value: 'st'},
            {title: 'Primary Objects', value: 'po'}
          ]}
        />
        <Form.DropdownInput
          placeholder='prepared by'
          on={{change: this.handleChangeFor({key: 'payeeUserId'})}}
          options={payeeOptions}
        />
        <Form.TextInput
          placeholder='project ID'
          on={{change: this.handleChangeFor({key: 'projectId'})}}
        />

        {/* <Form.DropdownInput
          placeholder='project'
          on={{change: this.handleChangeFor({key: 'projectId'})}}
          options={projectOptions}
        /> */}
        <Form.DropdownInput
          placeholder='not paid'
          on={{change: this.handleChangeFor({key: 'status'})}}
          options={[
            {title: 'not paid', value: ''},
            {title: 'all', value: 'all'},
            {title: 'Unpaid', value: 'unpaid'},
            {title: 'Partially Paid', value: 'partiallyPaid'},
            {title: 'Initialized By Client', value: 'initializedByClient'},
            {title: 'Paid', value: 'paid'},
            {title: 'Overdue', value: 'overdue'}
          ]}
        />
      <Form.DropdownInput
        placeholder='type'
        on={{change: this.handleChangeFor({key: 'type'})}}
        options={[
          {title: 'type', value: ''},
          {title: 'Initial Deposit', value: 'initialDeposit'},
          {title: 'Additions', value: 'additions'},
          {title: 'Signoff', value: 'signoff'},
          {title: 'Cost Revision', value: 'costRevision'},
          {title: 'PIF', value: 'pif'},
          {title: 'Change Order', value: 'changeOrder'},
          {title: 'Storage', value: 'storage'},
          {title: 'Field Expert Services', value: 'fieldExpertServices'},
          {title: 'Other', value: 'other'},
          {title: 'From DE', value: 'de'},
          {title: 'PO Cart', value: 'po cart'}
        ]}
      />
      </div>
    </div>
  }
}
