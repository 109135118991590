import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import PaymentMethodBody from '../payment-method/payment-method-body/payment-method-body.js';
import paytraceHelper from 'helpers/paytrace/paytrace-helper.js';
import Moment from 'moment';
import './credit-card-form.scss';

var paytrace = window.paytrace;

export default class CreditCardForm extends PaymentMethodBody {
  componentDidMount() {
    paytrace.setKey(paytraceHelper.getPublicKey({transaction: this.props.transaction}));
  }

  async onSubmit() {
    await super.onSubmit({processTransaction: async () => {
      var {transaction, receiptEmails} = this.props;
      var {state} = this;

      var requestParams = {
        credit_card: {
          encrypted_number: paytrace.encryptValue(state.ccNumber.trim()),
          expiration_month: state.ccExpirationMonth,
          expiration_year: state.ccExpirationYear
        },
        encrypted_csc: paytrace.encryptValue(state.ccSecurityCode.trim()),
        billing_address: {
          name: state.billingName,
          street_address: state.billingStreetAddress,
          city: state.billingCity,
          state: state.billingState,
          zip: state.billingZip
        },
        paymentMethod: 'creditCard',
        partialPaymentAmount: state.partialPaymentAmount,
        receiptEmails,
        transaction,
        cardType: this.leadingNumCardTypeMap[state.ccNumber[0]]
      };

      try {
        await lib.api.request({uri: 'transactions/pay', body: requestParams, useActualErrorMessages: true});

        this.props.onSuccessfulPayment({amountPaidInCents: this.paymentAmountInCents});
      }
      catch {}
    }});
  }

  get leadingNumCardTypeMap() {
    return {
      3: 'Amex',
      4: 'Visa',
      5: 'MasterCard',
      6: 'Discover'
    }
  }

  get formKeys() {
    return [
      'ccNumber',
      'ccSecurityCode',
      'billingName',
      'ccExpirationMonth',
      'ccExpirationYear',
      'billingStreetAddress',
      'billingCity',
      'billingState',
      'billingZip'
    ];
  }

  get paymentTypeName() {
    return 'card';
  }

  render() {
    var {transaction} = this.props;
    var {state} = this;

    var years = [];

    for (var i = 0; i <= 8; i++) {
       years.push(Moment().year() + i);
    }

    return super.render(!transaction ? null : (
      <>
        <div>
          <Form.TextInput {...className([_.includes(this.invalidKeys, 'ccNumber') && state.hasSubmitted && 'is-invalid'])} label='Credit Card #' placeholder='' value={state.ccNumber} on={{input: this.handleChangeFor({key: 'ccNumber'})}}/>
          <Form.TextInput {...className([_.includes(this.invalidKeys, 'ccSecurityCode') && state.hasSubmitted && 'is-invalid'])} label='Security Code/CSC' placeholder='' value={state.ccSecurityCode} on={{input: this.handleChangeFor({key: 'ccSecurityCode'})}}/>
          <Form.TextInput {...className([_.includes(this.invalidKeys, 'billingName') && state.hasSubmitted && 'is-invalid'])} label='Name On Card' placeholder='' value={state.billingName} on={{input: this.handleChangeFor({key: 'billingName'})}}/>
        </div>
        <div>
          <Form.DropdownInput
            {...className([_.includes(this.invalidKeys, 'ccExpirationMonth') && state.hasSubmitted && 'is-invalid'])}
            label='Expiration Month'
            value={state.ccExpirationMonth}
            on={{change: this.handleChangeFor({key: 'ccExpirationMonth'})}}
            options={[{value: '', title: ''}, ...lib.formData.monthOptions]}
          />
          <Form.DropdownInput
            {...className([_.includes(this.invalidKeys, 'ccExpirationYear') && state.hasSubmitted && 'is-invalid'])}
            label='Expiration Year'
            value={state.ccExpirationYear}
            on={{change: this.handleChangeFor({key: 'ccExpirationYear'})}}
            options={[{value: '', title: ''},..._.map(years, year => ({title: year, value: year}))]}
          />
        </div>
      </>
    ));
  }
}
