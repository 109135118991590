import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import Scroll from 'react-scroll';
import PaymentMethod from '../payment-method/payment-method.js';
import './payment-method-group.scss';
import upArrowIcon from '../../../../../assets/up-arrow-black.png';


export default class PaymentMethodGroup extends Component {
  constructor(props) {
    super(props);

    this.bind(['setActive']);

    this.ref = React.createRef();
  }

  setActive() {
    this.props.onSetActive({key: this.props.paymentMethodGroup.key});

    if (!this.props.isActive) setTimeout(() => Scroll.animateScroll.scrollTo(this.ref.current.offsetTop - 30, {smooth: true}));
    else setTimeout(() => Scroll.animateScroll.scrollToTop());
  }

  render() {
    var {paymentMethodGroup, activePaymentMethodKey, isActive} = this.props;
    var {paymentMethods, title} = paymentMethodGroup;

    return (
      <div {...className(['payment-method-group', isActive && 'is-active'])} ref={this.ref}>
        <div className='pm-group-header' onClick={this.setActive}>
          <img src={upArrowIcon} alt='arrow' style={{width: 16, height: 16, position: 'relative', ...(isActive ? {transform: 'rotate(180deg)'} : {transform: 'rotate(90deg)'}), backgroundColor: 'transparent'}}/>
          <div className='pm-group-header-text'>{title}</div>
        </div>
        {isActive && _.map(paymentMethods, paymentMethod => (
          <PaymentMethod
            paymentMethod={paymentMethod}
            isActive={activePaymentMethodKey === paymentMethod.key}
            onSetActive={this.props.setActivePaymentMethod}
            key={paymentMethod.key}
          />
        ))}
      </div>
    )
  }
}
