import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import Scroll from 'react-scroll';
import './payment-method.scss';

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.bind(['setActive']);

    this.ref = React.createRef();
  }

  setActive() {
    this.props.onSetActive({key: this.props.paymentMethod.key});

    if (!this.props.isActive) setTimeout(() => Scroll.animateScroll.scrollTo(this.ref.current.offsetTop - 30, {smooth: true}));
    else setTimeout(() => Scroll.animateScroll.scrollToTop());
  }

  render() {
    var {paymentMethod, isActive} = this.props;

    return (
      <div {...className(['payment-method', isActive && 'is-active'])} ref={this.ref}>
        <div className='pm-header' onClick={this.setActive}>
          <div className='is-active-dot'></div>
          <div className='pm-header-text'>{paymentMethod.header}</div>
        </div>
        <div className='pm-body'>{paymentMethod.renderBody()}</div>
      </div>
    )
  }
}
