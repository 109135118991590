import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {connect, Page} from '@henrybuilt/react-app/src';
import {Form} from 'henrybuilt-react-library';
import Scroll from 'react-scroll';
import {Link} from 'react-router-dom';
import PaymentMethodGroup from './payment-method-group/payment-method-group.js'
import CreditCardForm from './credit-card-form/credit-card-form.js';
import ElectronicCheckForm from './electronic-check-form/electronic-check-form.js';
import {brandKeyNameMap} from 'helpers/brand/brand-helper.js';
import './show-transaction-page.scss';

class ShowTransactionPage extends Page {
  key = 'show-transaction';
  authGroup = 'anyone';
  state = {isLoading: true, activePaymentMethodGroupKey: 'preferred'};

  constructor(props) {
    super(props);

    this.bind(['setActivePaymentMethod']);
  }

  async componentDidMount() {
    var user = _.get(this, 'props.session.user');

    var isEmployee = _.includes(['employee', 'admin'], _.get(user, 'authGroup'));

    var {data} = await lib.api.request({uri: 'transaction-for-token', body: {transactionToken: this.props.match.params.token}});
    var {transaction} = data;

    this.setState({transaction, receiptEmails: transaction.payerEmails, isLoading: false, isEmployee});
  }

  async onClientInitializedPayment({paymentType}) {
    var {transaction} = this.state;

    var paymentTypeMessage = {
      wire: 'initialized a wire',
      check: `mailed a check`,
      ach: 'initialized an ACH payment'
    }[paymentType] || 'Check Mailed';

    var clientConfirmedInitialization = window.confirm(`This will notify our team that you have ${paymentTypeMessage}. Do you wish to proceed?`);

    if (clientConfirmedInitialization) {
      await lib.api.request({uri: 'transactions/client-initialized-payment', body: {transaction, paymentType}, useActualErrorMessages: true});

      this.setState((prevState) => {
        return {activePaymentMethodKey: undefined , transaction: {...prevState.transaction, status: 'initializedByClient'}};
      });

      setTimeout(() => Scroll.animateScroll.scrollToTop());
    }
  }

  setActivePaymentMethod({key}) {
    this.setState({activePaymentMethodKey: this.state.activePaymentMethodKey === key ? '' : key});
  }

  get isValid() {
    var {transaction} = this.state;

    return transaction && transaction.amountInCents > 0 && transaction.type && transaction.brandKey;
  }

  get remainingAmountDue() {
    var remainingAmountDue = 0;

    if (this.state.transaction) {
      var {transaction} = this.state;

      remainingAmountDue = transaction.amountInCents - (transaction.amountPaidInCents || 0);
    }

    return remainingAmountDue;
  }

  render() {
    var {transaction, hasMadePayment, receiptEmails, activePaymentMethodKey, activePaymentMethodGroupKey, isLoading, isEmployee} = this.state;

    var onSuccessfulPayment = ({amountPaidInCents}) => {
      this.setState((prevState) => {
        var updatedAmountPaidInCents = amountPaidInCents + (prevState.transaction.amountPaidInCents || 0);
        var updatedStatus = updatedAmountPaidInCents >= prevState.transaction.amountInCents ? 'paid' : 'partiallyPaid';

        return {hasMadePayment: true, transaction: {...prevState.transaction, amountPaidInCents: updatedAmountPaidInCents, status: updatedStatus}};
      });

      if (this.state.transaction.status === 'paid') setTimeout(() => Scroll.animateScroll.scrollToTop());
    }

    var legalCompanyName = transaction && transaction.brandKey === 'st' ? 'Space Theory LLC' : 'Henrybuilt, Inc.';
    var companySpecificAccountNumber = transaction && transaction.brandKey === 'st' ? '138110446552' : '138110603638';

    var paymentMethodGroups = [
      {
        key: 'preferred',
        title: 'Preferred Payment Methods',
        paymentMethods: [
          {
            key: 'wire',
            header: 'Wire',
            renderBody: () => {
              return (
                <div className='static-billing-container'>
                  <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>Wires should be sent to:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>{legalCompanyName}</div>
                      <div className='static-billing-content'>Bank of America</div>
                      <div className='static-billing-content'>222 Broadway</div>
                      <div className='static-billing-content'>New York, NY 10038</div>
                    </div>
                  </div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>Routing/ABA #:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>026009593</div>
                    </div>
                  </div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>Account #:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>{companySpecificAccountNumber}</div>
                    </div>
                  </div>
                  <Form
                    useSubmitInput
                    on={{submit: () => this.onClientInitializedPayment({paymentType: 'wire'})}}
                    submitText={`I initiated a wire for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
                    isSubmittingText='Notifying Our Team'
                  />
                </div>
              );
            }
          },
          {
            key: 'electronicCheck',
            header: 'Electronic Check',
            renderBody: () => <ElectronicCheckForm
              hasMadePayment={hasMadePayment}
              receiptEmails={receiptEmails}
              transaction={transaction}
              onSuccessfulPayment={
                ({amountPaidInCents}) => (onSuccessfulPayment({amountPaidInCents}))
              }
            />
          },
        ]
      },
      {
        key: 'other',
        title: 'Other Payment Options',
        paymentMethods: [
          {
            key: 'ach',
            header: 'ACH Transfer (initiated by client)',
            renderBody: () => {
              return (
                <div className='static-billing-container'>
                  <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>ACH payments should be sent to:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>{legalCompanyName}</div>
                      <div className='static-billing-content'>Bank of America</div>
                      <div className='static-billing-content'>222 Broadway</div>
                      <div className='static-billing-content'>New York, NY 10038</div>
                    </div>
                  </div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>Routing/ABA #:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>125000024</div>
                    </div>
                  </div>
                  <div className='static-billing-line-item'>
                    <div className='static-billing-label'>Account #:</div>
                    <div className='static-billing-content-container'>
                      <div className='static-billing-content'>{companySpecificAccountNumber}</div>
                    </div>
                  </div>
                  <Form
                    useSubmitInput
                    on={{submit: () => this.onClientInitializedPayment({paymentType: 'ach'})}}
                    submitText={`I initiated an ACH payment for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
                    isSubmittingText='Notifying Our Team'
                  />
                </div>
              );
            }
          },
          {
            key: 'checkByMail',
            header: 'Check By Mail',
            renderBody: () => {
              return (
                <div className='static-billing-container'>
                  <div className='static-billing-line-item'>If you wish to send a paper check, please reach out to your {transaction && transaction.brandKey === 'st' ? 'Space Theory' : 'Henrybuilt'} contact to make arrangements</div>
                  {/* <Form useSubmitInput
                    on={{submit: () => this.onClientInitializedPayment({paymentType: 'check'})}}
                    submitText={`I sent a check for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
                    isSubmittingText='Notifying Our Team'
                  /> */}
                </div>
              );
            }
          },
          {
            key: 'creditCard',
            header: 'Credit Card',
            renderBody: () => <CreditCardForm
              hasMadePayment={hasMadePayment}
              receiptEmails={receiptEmails}
              transaction={transaction}
              onSuccessfulPayment={
                ({amountPaidInCents}) => (onSuccessfulPayment({amountPaidInCents}))
              }
            />
          },
        ]
      }
    ];

    if (transaction && transaction.brandKey === 'hb' && _.includes(['signoff', 'pif'], transaction.type)) {
      paymentMethodGroups = _.map(paymentMethodGroups, paymentMethodGroup => {
        paymentMethodGroup.paymentMethods = _.filter(paymentMethodGroup.paymentMethods, method => !_.includes(['creditCard'], method.key));

        return paymentMethodGroup;
      });
    }

    return super.render(
      <div className='show-transaction-page-content'>
        {!isLoading && (
          <>
            {this.isValid ? (
              <div {...className(['transaction', `status-${transaction && transaction.status}`])}>
                <div className='transaction-info'>
                  <div className='transaction-title'>{transaction.title}</div>
                  {isEmployee && (<div>
                    <Link className='edit-transaction-link' to={`/edit-transaction/${transaction.id}`}>edit transaction</Link>
                  </div>)}
                  {!_.includes(['initializedByClient', 'paid'], transaction.status) && transaction.description && <div className='transaction-description'>{transaction.description}</div>}
                  <div className='transaction-amount-status'>
                    {(transaction.status !== 'paid' && transaction.amountPaidInCents) ? `${lib.price.dinero(transaction.amountPaidInCents / 100).toFormat('$0,0.00')} / ` : ''}{lib.price.dinero(transaction.amountInCents / 100).toFormat('$0,0.00')}{transaction.status === 'paid' ? ' - paid' : ''}
                  </div>
                  {transaction.fileUrl && <a className='file-link button' href={transaction.fileUrl} rel='noopener noreferrer' target='_blank'>View Invoice</a>}
                  {!_.includes(['paid', 'initializedByClient'], transaction.status) && (
                    <div>
                      <Form.TextInput
                        value={transaction.payerEmails}
                        label='Receipt Email'
                        placeholder='address@domain.com'
                        on={{change: ({value}) => this.setState({receiptEmails: value})}}
                      />
                    </div>
                  )}
                </div>
                {!_.includes(['paid', 'initializedByClient'], transaction.status) && (
                  <div className='payment-methods'>
                    {_.map(paymentMethodGroups, paymentMethodGroup => {
                      var {key} = paymentMethodGroup;

                      return (
                        <PaymentMethodGroup
                          {...{key, paymentMethodGroup, activePaymentMethodKey}}
                          isActive={activePaymentMethodGroupKey === key}
                          setActivePaymentMethod={this.setActivePaymentMethod}
                          onSetActive={() => this.setState({activePaymentMethodGroupKey: activePaymentMethodGroupKey === key ? '' : key})}
                        />
                      );
                    })}
                  </div>
                )}
                {transaction.status === 'paid' && (
                  <div className='thank-you-note'>
                    <div>Thank you for your payment.</div>
                    <div>An email receipt will arrive shortly.</div>
                  </div>
                )}
                {transaction.status === 'initializedByClient' && (
                  <div>
                    <div {...className(['initialized-thank-you-note'])}>
                      <div>Thank you for initializing payment</div>
                      <div>Your payment will be processed when it is received.</div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='transaction-not-prepared-message'>
                We're sorry, but this transaction is not fully setup yet.
              </div>
            )}
            {transaction && (
              <div className='support-contact-info'>
                <div>For Support please call or email your {brandKeyNameMap[transaction.brandKey === 'st' ? 'st' : 'hb']} contact</div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect({})(ShowTransactionPage);
