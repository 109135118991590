var paytraceHelper = {};

paytraceHelper.getPublicKey = ({transaction}) => {
  var key;
  var environment = process.env.NODE_ENV;

  if (environment === 'production') {
    if (transaction.brandKey === 'st') {
      key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy1mc399HcVv97DOhGJX8D0aBCRmCgG7nMgdukQRIQTqR7NR+oW+/i4n+V8/S1D39lAo0UbEapK+tdIUHGQfIle7LgRwRTKaVZZz/bSfTzMrDr2lfFBhT+wbKniLLO5TL25AkrgczZ/trj6lQX7aKW3z6wTPj+QI/TTCJmAHg26rQET1AxO/cEIGQdTdU2rg1xyUKjlfFDwdxSENVAo90kYtYgY2P1ikIwsdynJ75nfKbPj5E+wvO6+wZoucCKfQt/RJy4wwrlgMy74TbZy+0Z9QEXVM45vR9kiiGKttvMWru8OsyUDTdTsYV290tampcE6ep4p6ewCvsHJJZD/1+2wIDAQAB-----END PUBLIC KEY-----";
    }
    else {
      key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqNPrg+4wg5VY3+xVmctrejydzrjvYUHc03ncIl2VnoOeyFgpyi72ldT7CcLRoP98fbxh6LjlX2TXzCekjPLTEyZuhAmCOV36cunmGkPxLI+mMEQd+K8Xfj/WBiIL3nplC1PdE/xn0VoKOyyFfl1MR9Lo+jQ/sm/y9hBtkJyhOm1bFj5/9tgODVzFka6FGrSL1ezyo1woMc7G6VJVtKRwY3kkTWC0Mnk+QtGeenHMOTOhUzL44KPS+/JelM+LyAS1J3NfGiKsbsyjMJvkq4GhP0DfHgsJ9g9AtF5YXelO8iOsAnx2fpMJVuU68I1XO3Pggc4I9tEq4V+x4Z611H4E+QIDAQAB-----END PUBLIC KEY-----";
    }
  }
  else {
    key = `-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsGOcaNcP0deIn45OTL1CTn7CKZ/i9YL5bAVgkq40O/bIeLfZ5yhYzlNAhPymIwIvetFJTJ4iHj/jt6W8K8pLaBZzTz6W2/L/gOtwO0v5PTlks/ovoKSDqWCFDwDvM/F1vcNrdSRBP/oLJTNPgij0mOgzZpMp+NdMOclOIGxFG4+tLB59SBlql20IZCaHddYiJNHC4cno1Z29EbulrVULKMOVAC88pM/yLgd1SLbHDptt23tJINDaKnpQtzGsvxEKhYGWiS+Yot/KAuFBk6HS2jZ7VbkqZ06dheEgp7Wlh8G3yc9XKZaxE8u+bHld4eqnBZV0f+wu9s7U4tnmOz1/1wIDAQAB-----END PUBLIC KEY-----`;
  }

  return key;
}

export default paytraceHelper;
